.App {
  text-align: center;
  width: 800px;
  height: 600px;
  color: #222;
  background: #fff;
  position: relative;
}

.App-logo {
  height: 80px;

  pointer-events: none;
}

p {
  color: #222;
  font-size: 22px;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* .App-header {
  height: auto;
} */

.App-link {
  color: #61dafb;
}

.Top {
  position: absolute;
  width: 100%;
  top: 40px;
}

.open {
  font-size: 220px;
}

.closed {
  font-size: 170px;
}

.Bottom {
  position: absolute;
  width: 100%;
  bottom: 40px;
}
.Aligner {
  display: flex;
  align-items: center;
  height: 340px;
  justify-content: center;
}

.Aligner-item {
  flex: 1;
}

.message p {
  border: 3px solid;
  display: inline-block;
  padding: 40px;
  max-width: 590px;
  font-size: 35px;
  font-weight: bold;
}

.Aligner-item--top {
  align-self: flex-start;
}

.Aligner-item--bottom {
  align-self: flex-end;
}

.Aligner-item--fixed {
  flex: none;
  max-width: 50%;
}

.phones span {
  font-weight: bold;
}


.flex-container {
  display: flex;
  height: 110px;
  align-items: center;
  justify-content: center;
  gap: 40px;
}

.flex-container p, .flex-container h2 {
  margin: 7px;
}


.flex-container svg {
  margin: -4px;
  margin-right: 10px;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
